<script setup lang="ts">
import { useMembershipStore } from '@/stores/membership'
import { UserInfo } from '@/models/auth'

const membershipStore = useMembershipStore()
const { $modal, $cookies } = useNuxtApp()
const $config = useRuntimeConfig()

const showForm = ref(false)
const wasSubmitted = ref(false)
const userInfo = ref<UserInfo>($cookies.getUserInfo())

watch(
  () => membershipStore.hasLoaded,
  () => {
    if (membershipStore.hasLoaded) openModal()
  },
)

function openModal() {
  if (
    !membershipStore.showTrialEndedModal ||
    $cookies.trialExpiredCookieExists()
  ) {
    return
  }

  userInfo.value = $cookies.getUserInfo()

  $modal.open('expiredTrial')
}

function doNotShowAgain() {
  $cookies.setTrialExpiredCookie()
  $modal.close('expiredTrial')
}

function onSubmitted() {
  $cookies.setTrialExpiredCookie()

  wasSubmitted.value = true

  setTimeout(() => {
    $modal.close('expiredTrial')
  }, 5000)
}
</script>

<template>
  <BaseModal name="expiredTrial">
    <section class="max-w-sm">
      <div v-if="!wasSubmitted">
        <h2 class="mb-9 text-2xl font-bold">
          Your trial of Benchmark Source has ended.
        </h2>

        <p class="mb-5 text-lg">
          To continue with your Benchmark Source Subscription, please
          <span v-if="!showForm">contact us</span>
          <span v-else>check your details below</span>
        </p>

        <div v-if="!showForm">
          <div class="flex justify-center">
            <BaseButton class="mb-6" variant="primary" @click="showForm = true">
              Contact us
            </BaseButton>
          </div>

          <input
            id="do-not-show-again"
            type="checkbox"
            class="cursor-pointer text-error accent-p-500"
            @click="doNotShowAgain"
          />
          <label for="do-not-show-again" class="cursor-pointer select-none">
            Don’t show this message again
          </label>
        </div>
      </div>

      <FormBuilder
        v-show="showForm"
        :form-id="$config.formidableForms.expiredTrial"
        field-extra-classes="mb-3"
        full-width-to-button
        success-message="Thank you for contacting us, one of our team will be in touch shortly."
        :default-values="{
          'First Name': userInfo.firstName,
          'Last Name': userInfo.lastName,
          'Business Email': userInfo.email,
        }"
        hide-form-on-submit-success
        @form-send-success="onSubmitted"
      />
    </section>
  </BaseModal>
</template>
