<script setup lang="ts">
const { $modal } = useNuxtApp()
const { isRequestTrialButtonActive } = useShowRequestTrial()

const conditionalPadding = computed(() => {
  if (!isRequestTrialButtonActive.value) return

  return 'pb-16'
})

const defaultValues = ref({ checkbox: 'Subscribe to the Benchmark Newsletter' })
const showMessage = ref(false)
const handleEvent = (_modal) => {
  setTimeout(() => {
    $modal.close(_modal)
  }, 4000)
}
</script>

<template>
  <footer class="bg-s-900" data-testid="footer">
    <div
      class="pb mx-auto max-w-content-container items-center justify-between gap-10 px-5 py-6 text-white sm:py-14 lg:flex"
      :class="conditionalPadding"
    >
      <!-- Trademark -->
      <div>
        <img
          src="https://res.cloudinary.com/benchmarkminerals/image/upload/f_auto,q_auto,w_250/v1678201645/assets/benchmark-logo-white_kjke3x.webp?_i=AA"
          alt="Benchmark Minerals"
        />
        <p class="mt-3 text-xs">© Benchmark Mineral Intelligence Limited</p>
      </div>

      <!-- Links -->
      <div
        class="mt-6 flex flex-wrap gap-x-14 text-sm lg:mt-0 [&_a:hover]:underline"
      >
        <!-- Left -->
        <div class="flex flex-grow flex-col md:flex-row md:gap-14">
          <div class="w-4/12 lg:w-full">
            <LinkOrAnchor class="block whitespace-nowrap" to="/terms">
              TCs
            </LinkOrAnchor>
            <LinkOrAnchor class="block whitespace-nowrap" to="/privacy">
              Privacy Policy
            </LinkOrAnchor>
            <LinkOrAnchor class="block whitespace-nowrap" to="/cookies">
              Cookies
            </LinkOrAnchor>
            <LinkOrAnchor class="block whitespace-nowrap" to="/contact">
              Contact
            </LinkOrAnchor>
          </div>

          <div class="w-6/12 lg:w-full">
            <LinkOrAnchor class="block whitespace-nowrap" to="/about">
              About
            </LinkOrAnchor>
            <LinkOrAnchor
              class="block whitespace-nowrap"
              to="/category/critical-minerals"
            >
              Critical Minerals
            </LinkOrAnchor>
            <LinkOrAnchor
              class="block whitespace-nowrap"
              to="/category/batteries"
            >
              Batteries
            </LinkOrAnchor>
            <LinkOrAnchor
              class="hidden whitespace-nowrap md:block"
              to="/category/sustainability"
            >
              Sustainability
            </LinkOrAnchor>
          </div>
        </div>

        <!-- Right -->
        <div class="flex flex-grow flex-col md:flex-row md:gap-14">
          <div class="w-4/12 min-w-min lg:w-full">
            <LinkOrAnchor
              class="block whitespace-nowrap"
              to="/category/new-energy"
            >
              New Energy
            </LinkOrAnchor>
            <LinkOrAnchor
              class="block whitespace-nowrap"
              to="/category/geopolitics"
            >
              Geopolitics
            </LinkOrAnchor>
            <LinkOrAnchor class="block whitespace-nowrap" to="/video">
              Video
            </LinkOrAnchor>
            <LinkOrAnchor
              class="block whitespace-nowrap"
              to="/data-visualisation"
            >
              Data Visualisation
            </LinkOrAnchor>
          </div>

          <div class="w-6/12 lg:w-full">
            <LinkOrAnchor
              class="block whitespace-nowrap"
              to="/downloads/magazine"
            >
              Downloads
            </LinkOrAnchor>
            <LinkOrAnchor class="block whitespace-nowrap" to="/newsletter">
              Newsletter
            </LinkOrAnchor>
            <LinkOrAnchor
              class="block whitespace-nowrap md:hidden"
              to="/category/sustainability"
            >
              Sustainability
            </LinkOrAnchor>
          </div>
        </div>
      </div>

      <!-- Social -->
      <SocialLinks
        class="mt-4 gap-x-4 lg:mt-0"
        icon-classes="h-5 w-5 fill-white"
        hide-share-button
      />
    </div>
  </footer>

  <BaseModal name="login" data-testid="login-modal">
    <LoginForm module="modal" />
  </BaseModal>

  <BaseModal
    v-slot="{ onLoaded }"
    name="subscribe"
    data-testid="subscribe-modal"
    async
    :show-message="showMessage"
  >
    <SubscribeForm
      is-modal
      :default-values="defaultValues"
      @loaded="onLoaded"
      @show-message-success="handleEvent('subscribe')"
    />
  </BaseModal>

  <BaseModal
    v-slot="{ onLoaded }"
    name="requestATrial"
    data-testid="requestATrial-modal"
    async
    bg-white
    :show-message="showMessage"
  >
    <SubscribeForm
      is-request-a-trial
      is-modal
      @loaded="onLoaded"
      @show-message-success="handleEvent('requestATrial')"
    />
  </BaseModal>

  <BaseModal name="forgotPassword" data-testid="forgot-password-modal">
    <ForgotPasswordForm @success="$modal.close('forgotPassword')" />
  </BaseModal>
</template>
