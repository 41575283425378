<script setup lang="ts">
const { levelTwoExists, activeLevelOne } = useNavigation()
</script>

<template>
  <MobileNavigationLink
    v-if="levelTwoExists"
    data-testid="contextual-navigation"
    :level-one-link="activeLevelOne"
    is-contextual
  />
</template>
