<script setup lang="ts">
import { useMembershipStore } from '@/stores/membership'
import { UserInfo } from '@/models/auth'
import {
  isActiveMembership,
  thereIsAMembershipActive,
} from '@/utils/membership.utils'
import { getAdaptedMembershipStatuses } from '@/utils/adapters'
import { SUBSCRIPTIONS_SLUGS } from '@/constants/memberships.const'

const membershipStore = useMembershipStore()
const { $modal, $cookies, $restClient } = useNuxtApp()
const $config = useRuntimeConfig()

const showForm = ref(false)
const wasSubmitted = ref(false)
const userInfo = ref<UserInfo>($cookies.getUserInfo())
const modalName = 'noSourceSubscription'

watch(
  () => membershipStore.hasLoaded,
  () => {
    if (membershipStore.hasLoaded) openModal()
  },
)

async function openModal() {
  const allMemberships = getAdaptedMembershipStatuses(
    await $restClient.getMembershipStatuses({ slugs: SUBSCRIPTIONS_SLUGS }),
  )

  const source = allMemberships.Source[0]
  const allButSource = [
    ...allMemberships.Sustainability,
    ...allMemberships.Forecast,
    ...allMemberships['Market Assessments'],
    ...allMemberships['Price Assessments'],
  ]

  if (
    membershipStore.showTrialEndedModal &&
    !$cookies.trialExpiredCookieExists()
  )
    return
  if ($cookies.noSourceSubscriptionCookieExists()) return
  if (isActiveMembership(source)) return
  if (!thereIsAMembershipActive(allButSource)) return

  userInfo.value = $cookies.getUserInfo()

  $modal.open(modalName)
}

function doNotShowAgain() {
  $cookies.setNoSourceSubscriptionCookie()
  $modal.close(modalName)
}

function onSubmitted() {
  $cookies.setNoSourceSubscriptionCookie()

  wasSubmitted.value = true

  setTimeout(() => {
    $modal.close(modalName)
  }, 5000)
}
</script>

<template>
  <BaseModal :name="modalName">
    <section class="max-w-sm">
      <div v-if="!wasSubmitted">
        <h2 class="mb-3 text-xl font-bold">
          {{ userInfo.firstName || userInfo.name }}, although you have a
          Benchmark Subscription, it is not to Benchmark Source.
        </h2>

        <p class="mb-6 text-lg">
          If you would like to access Benchmark Source, please
          <span v-if="!showForm">contact us</span>
          <span v-else>check your details below</span>
        </p>

        <div v-if="!showForm">
          <div class="flex justify-center">
            <BaseButton class="mb-6" variant="primary" @click="showForm = true">
              Contact us
            </BaseButton>
          </div>

          <input
            id="do-not-show-again"
            type="checkbox"
            class="cursor-pointer text-error accent-p-500"
            @click="doNotShowAgain"
          />
          <label for="do-not-show-again" class="cursor-pointer select-none">
            Don’t show this message again
          </label>
        </div>
      </div>

      <FormBuilder
        v-show="showForm"
        :form-id="$config.formidableForms.expiredTrial"
        field-extra-classes="mb-3"
        full-width-to-button
        success-message="Thank you for contacting us, one of our team will be in touch shortly."
        :default-values="{
          'First Name': userInfo.firstName,
          'Last Name': userInfo.lastName,
          'Business Email': userInfo.email,
        }"
        @form-send-success="onSubmitted"
      />
    </section>
  </BaseModal>
</template>
