<script setup lang="ts">
const { $cookies } = useNuxtApp()

const { isRequestTrialButtonActive } = useShowRequestTrial()

const showBanner = ref(false)

const bannerPosition = computed(() => {
  if (!isRequestTrialButtonActive.value) return

  return 'bottom-11 md:bottom-0'
})

onMounted(() => {
  if (!$cookies.getConsentCookie()) {
    showBanner.value = true
  }
})

function AcceptCookies() {
  $cookies.setConsentCookie(true)
  showBanner.value = false
}
</script>

<template>
  <div
    v-if="showBanner"
    class="fixed bottom-0 z-[9999] w-full bg-s-800 py-4"
    :class="bannerPosition"
  >
    <div
      class="mx-auto flex max-w-content-container flex-col items-center justify-between gap-4 px-5 md:flex-row"
    >
      <p class="text-white">
        We use cookies, pixels, and other technologies (collectively, "cookies")
        to recognize your browser or device and collect other basic data.
        <LinkOrAnchor to="/cookies" class="underline"
          >Read more about cookies here</LinkOrAnchor
        >.
      </p>
      <button
        class="min-w-fit rounded bg-p-500 px-5 py-2"
        @click="AcceptCookies"
      >
        OK
      </button>
    </div>
  </div>
</template>
