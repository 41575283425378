<script setup lang="ts">
const { isRequestTrialButtonActive } = useShowRequestTrial()
</script>

<template>
  <div
    v-if="isRequestTrialButtonActive"
    class="fixed bottom-0 z-[9998] w-full bg-p-400 md:hidden"
  >
    <button
      class="w-full py-2 text-center text-lg font-semibold"
      @click="$modal.open('requestATrial')"
    >
      Request a free trial
    </button>
  </div>
</template>
