import { navigation } from '@/constants/navigation'
import { LevelOneLink, LevelTwoLink } from '@/models/navigation'
import { reverse } from '@/utils/array'

/**
 * Encapsulates all the necessary logic to render and keep track of
 * the state of the navigation.
 *
 * @returns {Object} A object with reactive properties such as:
 *    - `navigation[]`: the array of navigation and subnavigation links
 *    - `activeLevelOne`: a computed reference to the active navigation link.
 *      (or "level one link").
 *    - `activeLevelTwo`: a computed reference to the active subnavigation link
 *       (or "level two link").
 *    - `levelTwoExists`: a computed value that tells if the currently active
 *       navigation link has any subnavigation.
 *    - `getLevelOnePath()`: a method that returns the full path of a navigation
 *       link.
 *    - `getLevelTwoPath()`: a method that returns the full path of a
 *       subnavigation link.
 */
export function useNavigation() {
  const route = useRoute()

  const activeLevelOne = computed<LevelOneLink>(() =>
    reverse(navigation).find((link) => {
      return route.path.startsWith(link.path)
    }),
  )

  const levelTwoExists = computed(
    () => activeLevelOne.value && activeLevelOne.value.children,
  )

  const activeLevelTwo = computed<LevelTwoLink>(() => {
    if (!levelTwoExists.value) return

    const levelTwo = route.path.replace(
      new RegExp(`^${activeLevelOne.value.path}`),
      '',
    )

    const levelTwoLinks = activeLevelOne.value.children

    return levelTwo
      ? levelTwoLinks.find((link) => link.path === levelTwo)
      : levelTwoLinks.find((link) => link.path === '/')
  })

  const getLevelOnePath = (link: LevelOneLink) => {
    const rootChild = link.children?.find((child) => child.root)
    return rootChild ? `${link.path}${rootChild.path}` : link.path
  }

  const getLevelTwoPath = (link: LevelTwoLink, levelOneLink?: LevelOneLink) => {
    return levelOneLink
      ? `${levelOneLink.path}${link.path}`
      : `${activeLevelOne.value.path}${link.path}`
  }

  return {
    navigation: computed(() => navigation),
    activeLevelOne,
    levelTwoExists,
    activeLevelTwo,
    getLevelOnePath,
    getLevelTwoPath,
  }
}

/**
 * Executes the passed callback every time the mobile menu's visibility
 * changes.
 *
 * @param {function} callback
 */
export function useMobileMenuVisibilityWatcher(callback: Function) {
  const { $modal } = useNuxtApp()
  const isVisible = computed(() => $modal.mobileMenu)
  watch(isVisible, () => callback(isVisible.value))
}
