<script setup lang="ts">
import { useNavigation } from '~/composables/navigation'

const { levelTwoExists } = useNavigation()

const headerSourceLogoHeight = 84.65
let prevScrollPos = 0
const ELEMENT_ID = {
  DESKTOP: 'header-desktop',
  MOBILE: 'header-mobile',
}

const onScrollHideOrShow = (elId: string, elHeight: number = 0) => {
  const currentScrollPos = window.scrollY
  const navbar = document.getElementById(elId)
  if (elHeight === 0) {
    elHeight = navbar.offsetHeight
  }

  if (elId !== ELEMENT_ID.DESKTOP) {
    document.getElementById(ELEMENT_ID.DESKTOP).style.translate = '0px'
  } else {
    document.getElementById(ELEMENT_ID.MOBILE).style.translate = '0px'
  }

  if (prevScrollPos < currentScrollPos && currentScrollPos > elHeight) {
    navbar.style.translate = `0 -${elHeight}px`
    document.getElementById('login-search-second-navbar').style.opacity = '1'
  } else if (prevScrollPos > currentScrollPos) {
    navbar.style.translate = '0px'
    document.getElementById('login-search-second-navbar').style.opacity = '0'
  }
  prevScrollPos = currentScrollPos
}

onMounted(() => {
  window.addEventListener('scroll', () => {
    if (window.innerWidth >= 1280) {
      onScrollHideOrShow(ELEMENT_ID.DESKTOP, headerSourceLogoHeight)
    } else {
      onScrollHideOrShow(ELEMENT_ID.MOBILE)
    }
  })
})
</script>

<template>
  <div class="absolute flex min-h-full w-full flex-col overflow-y-auto">
    <Head>
      <title>
        Benchmark Source | Supply Chain Intelligence for the Energy Transition
      </title>
    </Head>

    <div
      :id="ELEMENT_ID.DESKTOP"
      class="transition-translate fixed z-50 mt-9 hidden w-full bg-white header-breakpoint:block"
    >
      <TheHeader />
      <TheNavigation />
    </div>
    <div :id="ELEMENT_ID.MOBILE" class="transition-translate fixed z-50 w-full">
      <TheTicker class="hidden w-full md:block" />
      <MobileHeader class="header-breakpoint:hidden" />
      <MobileContextualNavigation class="header-breakpoint:hidden" />
    </div>

    <MobileNavigation class="block header-breakpoint:hidden" />

    <main
      class="mt-[45px] flex grow flex-col md:mt-[80px] header-breakpoint:mt-[168.65px]"
      :class="
        levelTwoExists &&
        'mt-[85px] md:mt-[120px] header-breakpoint:mt-[212.65px]'
      "
    >
      <div
        v-if="$config.isPreviewSite && !$auth.store.canPreviewContent"
        class="mx-auto flex max-w-md grow flex-col justify-center px-5 text-center"
      >
        <div>
          <button
            data-testid="login-button"
            class="underline"
            @click.prevent="$modal.open('login')"
          >
            Login
          </button>
          with an author, editor, or administrator account to see the content
          preview.
        </div>
      </div>

      <slot v-else />
    </main>

    <CookieConsentBanner />
    <ButtonRequestATrial />

    <TheFooter />

    <ModalLightbox name="imageLightbox" data-testid="image-lightbox-modal" />
    <ModalExpiredTrial />
    <ModalNoSourceSubscription />
  </div>
</template>

<style>
.page-enter-active,
.page-leave-active {
  transition: opacity 0.15s ease;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
}

.transition-translate {
  transition: translate 0.3s ease;
}
</style>
