<script setup lang="ts">
import tippy from 'tippy.js'
import IconAngleDown from '@/assets/icons/angle-down.svg'
import IconAngleUp from '@/assets/icons/angle-up.svg'
import IconLock from '@/assets/icons/lock.svg'

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  percentageChange: {
    type: Number,
    default: null,
  },
  today: {
    type: Number,
    default: null,
  },
  publishedDate: {
    type: String,
    required: true,
  },
  updateFrequencyText: {
    type: String,
    required: true,
  },
  hasAccess: {
    type: Boolean,
    required: true,
  },
})

const indexName = `${props.name} Index`
const elId = `${props.name.toLowerCase().replace(' ', '-')}`
const isPercentageChangeZero = props.percentageChange === 0
const indexPercentage = isPercentageChangeZero
  ? '0.0%'
  : `${props.percentageChange}%`
const isPercentageChangeGreaterThanZero = props.percentageChange > 0

const iconAngleHidden = isPercentageChangeZero && 'hidden'
const indexPercentageColor = computed(() => {
  if (isPercentageChangeZero) return 'text-s-500'
  if (isPercentageChangeGreaterThanZero) return 'text-green-light'
  return 'text-error-light'
})

const iconArrowDown =
  '<svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.293 7.293a1 1 0 0 1 1.414 0L10 10.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 0-1.414Z"/></svg>'
const iconArrowUp =
  '<svg width="18" height="18" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.707 11.707a1 1 0 0 1-1.414 0L9 8.414l-3.293 3.293a1 1 0 0 1-1.414-1.414l4-4a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1 0 1.414Z"/></svg>'
const iconL =
  '<svg xmlns="http://www.w3.org/2000/svg" width="12.7" height="16" viewBox="0 0 44 56" fill="currentColor"><path d="M38 20h-2v-6a14 14 0 0 0-28 0v6H6a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6h32a6 6 0 0 0 6-6V26a6 6 0 0 0-6-6Zm-26-6a10 10 0 0 1 20 0v6H12v-6Zm28 36a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V26a2 2 0 0 1 2-2h32a2 2 0 0 1 2 2v24Z"/><path  d="M22 31a4 4 0 0 0-2 7.4V43a2 2 0 0 0 4 0v-4.6a4 4 0 0 0-2-7.4Z"/></svg>'

const hasShowData = computed(() => props.hasAccess)

const createDiv = () => {
  const div = document.createElement('div')
  const spanTitle = document.createElement('span')
  const divValues = document.createElement('div')
  const spanToday = document.createElement('span')
  const spanPercentage = document.createElement('span')
  const spanIcon = document.createElement('span')
  const spanDate = document.createElement('span')
  const spanPeriod = document.createElement('span')

  if (hasShowData.value) {
    spanToday.textContent = props.today?.toString()
  } else {
    spanToday.innerHTML = iconL
  }

  spanTitle.textContent = indexName
  spanPercentage.textContent = indexPercentage
  spanIcon.innerHTML = isPercentageChangeGreaterThanZero
    ? iconArrowUp
    : iconArrowDown
  spanDate.textContent = props.publishedDate.repeat(1)
  spanPeriod.textContent = props.updateFrequencyText.repeat(1)

  spanPercentage.classList.add(indexPercentageColor.value)
  spanIcon.classList.add(indexPercentageColor.value)

  divValues.style.display = 'flex'
  divValues.style.alignItems = 'center'
  divValues.style.marginBottom = '8px'
  divValues.style.fontWeight = '600'
  divValues.style.color = 'white'
  spanToday.style.marginRight = '8px'
  div.style.backgroundColor = '#333'
  div.style.borderRadius = '12px'
  div.style.display = 'flex'
  div.style.flexDirection = 'column'
  div.style.padding = '12px'
  div.style.fontSize = '12px'
  spanDate.style.fontSize = '10px'
  spanPeriod.style.fontSize = '8px'

  divValues.appendChild(spanToday)
  divValues.appendChild(spanPercentage)
  if (!isPercentageChangeZero) divValues.appendChild(spanIcon)
  div.appendChild(spanTitle)
  div.appendChild(divValues)
  div.appendChild(spanDate)
  div.appendChild(spanPeriod)
  return div
}

onMounted(() => {
  tippy(`#${elId}`, {
    content: createDiv().outerHTML,
    allowHTML: true,
  })
})

onUpdated(() => {
  const tooltip = document.querySelector(`#${elId}`)
  if (tooltip) {
    tooltip._tippy.setContent(createDiv().outerHTML)
  }
})
</script>

<template>
  <div
    v-if="name"
    :id="elId"
    class="relative flex items-center"
    :class="hasShowData ? '' : 'mx-1'"
  >
    <span class="mr-2 whitespace-nowrap font-normal">{{ indexName }}</span>
    <IconLock
      v-if="!hasShowData"
      class="mx-1 max-h-4 w-auto cursor-pointer hover:text-green-light"
      @click="$modal.open('login')"
    />
    <span v-else>{{ today }}</span>
    <span
      class="ml-2 flex min-w-[45px] items-center"
      :class="indexPercentageColor"
    >
      {{ indexPercentage }}
      <IconAngleUp
        v-if="isPercentageChangeGreaterThanZero"
        :class="iconAngleHidden"
      />
      <IconAngleDown v-else :class="iconAngleHidden" />
    </span>
  </div>
</template>
