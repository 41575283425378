import { Subscription } from '@/models/subscriptions.types'

const ACTIVE = 'active'

export function isActiveMembership(membership: Subscription): Boolean {
  return membership.status === ACTIVE
}

export function thereIsAMembershipActive(memberships: Subscription[]): Boolean {
  return memberships.some((membership) => membership.status === ACTIVE)
}
