import { Navigation } from '@/models/navigation'

export const navigation: Navigation = [
  { path: '/', label: 'Home' },
  {
    path: '/category/critical-minerals',
    label: 'Critical Minerals',
  },
  {
    path: '/category/batteries',
    label: 'Batteries',
  },
  {
    path: '/category/sustainability',
    label: 'Sustainability',
  },
  {
    path: '/category/new-energy',
    label: 'New Energy',
  },
  {
    path: '/category/geopolitics',
    label: 'Geopolitics',
  },
  {
    path: '/data-visualisation',
    label: 'Data Visualisation',
  },
  {
    path: '/video',
    label: 'Video',
    variant: 'secondary',
  },
  {
    path: '/downloads',
    label: 'Downloads',
    variant: 'secondary',
    children: [
      { path: '/magazine', label: 'Magazine', root: true },
      {
        path: '/presentations',
        label: 'Presentations',
      },
      {
        path: '/special-issues',
        label: 'Special Issues',
      },
    ],
  },
  {
    path: '/newsletter',
    label: 'Newsletter',
    variant: 'secondary',
  },
]
