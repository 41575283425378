/**
 * This composable contains global initializations, such as session
 * restoring from cookies; and Vue Router's navigation guards.
 * Use it once and within a component that is common across all pages.
 */
export function useInitializations() {
  const { $auth, $modal, $membership } = useNuxtApp()

  onBeforeMount(async () => {
    await $auth.restoreAuthState()

    if ($auth.store.isAuthenticated) {
      $membership.loadMembershipStatuses()
    }
  })

  const router = useRouter()

  router.afterEach(() => {
    $modal.reset()
  })
}
