<script setup lang="ts">
import { useElementHover } from '@vueuse/core'

defineProps({
  to: {
    type: String,
    required: true,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
})

const link = ref()
const isHovered = useElementHover(link)
</script>

<template>
  <LinkOrAnchor
    ref="link"
    :to="to"
    class="relative inline-block py-3 text-sm font-semibold"
  >
    <slot />
    <div
      data-testid="active-link-underline"
      class="absolute -bottom-0.5 h-line-6 w-full bg-p-500"
      :class="
        isActive || isHovered
          ? 'opacity-100 transition-opacity duration-300'
          : 'opacity-0'
      "
    />
  </LinkOrAnchor>
</template>
