import { useMembershipStore } from '@/stores/membership'

export function useShowRequestTrial() {
  const membershipStore = useMembershipStore()
  const { $modal, $auth } = useNuxtApp()

  const isRequestTrialButtonActive = computed(() => {
    return (
      !$modal.isRequestATrialActive() &&
      !(
        $auth.membershipStore.hasOnlyTrialActive ||
        $auth.membershipStore.isSubscriberActive
      ) &&
      membershipStore.hasLoaded
    )
  })

  return {
    isRequestTrialButtonActive,
  }
}
