<script setup lang="ts">
import { useNavigation } from '~/composables/navigation'
import { useInitializations } from '~/composables/initializations'
import { useAuthStore } from '@/stores/auth'
import IconSearch from '@/assets/icons/search.svg'

const authStore = useAuthStore()

const {
  navigation,
  activeLevelOne,
  activeLevelTwo,
  levelTwoExists,
  getLevelOnePath,
  getLevelTwoPath,
} = useNavigation()

useInitializations()

const showAccountMenu = ref(false)
</script>

<template>
  <div class="relative">
    <nav
      aria-label=""
      data-testid="desktop-navigation"
      class="border-t-2 border-b-2 border-s-200"
    >
      <div class="flex justify-center">
        <div
          class="relative flex max-w-content-container grow justify-between px-4 navigation-adjustment:justify-center navigation-adjustment:gap-x-4"
        >
          <NavigationLink
            v-for="link in navigation"
            :key="link.path"
            data-testid="level-one-link"
            :to="getLevelOnePath(link)"
            :is-active="link === activeLevelOne"
            :data-active="link === activeLevelOne"
          >
            {{ link.label }}
          </NavigationLink>

          <ClientOnly>
            <div
              v-if="authStore.token !== undefined"
              id="login-search-second-navbar"
              class="absolute top-1/2 right-0 mx-auto -translate-y-1/2 px-5 opacity-0 transition-opacity"
            >
              <div class="flex items-center justify-end gap-4">
                <div
                  v-if="!authStore.isAuthenticated"
                  class="flex items-center justify-end gap-2 navigation-adjustment:gap-4"
                >
                  <a
                    href="/login"
                    class="text-xs font-semibold"
                    data-testid="login-link"
                    @click.prevent="$modal.open('login')"
                  >
                    Login
                  </a>
                  <span class="h-4 border-r-2 border-s-200"></span>
                </div>

                <div
                  v-if="authStore.isAuthenticated"
                  class="text-xs font-semibold"
                >
                  <div class="relative" @mouseleave="showAccountMenu = false">
                    <button
                      data-testid="login-link"
                      @click="showAccountMenu = !showAccountMenu"
                    >
                      Account
                    </button>
                    <AccountMenu v-if="showAccountMenu" />
                  </div>
                </div>

                <LinkOrAnchor to="/search">
                  <IconSearch />
                </LinkOrAnchor>
              </div>
            </div>
          </ClientOnly>
        </div>
      </div>
    </nav>

    <nav
      v-if="levelTwoExists"
      aria-label=""
      data-testid="desktop-subnavigation"
      class="border-b-2 border-s-200"
    >
      <div class="flex justify-center">
        <div class="mx-3 flex gap-4">
          <NavigationLink
            v-for="link in activeLevelOne.children"
            :key="link.path"
            data-testid="level-two-link"
            :to="getLevelTwoPath(link)"
            :is-active="link === activeLevelTwo"
            :data-active="link === activeLevelTwo"
          >
            {{ link.label }}
          </NavigationLink>
        </div>
      </div>
    </nav>
  </div>
</template>
